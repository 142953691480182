const rootConfig = document.getElementById('root-config');
const appConfig = rootConfig ? JSON.parse(rootConfig.dataset.config) : {};
const Config = {
    'csrf_token': '',
    'can_leave_review': false,
    'dark_mode': false,
    'timezones': [],
    'user': {
        'id': '',
        'slug': '',
        'email': '',
        'fullName': '',
        'timezone': '',
        'date_format': '',
        'time_format': '',
        'sunday_is_first_day': false,
        'is_rtl': false,
        'locale': '',
        'is_owner': false,
        'has_trial_subscription': false,
        'has_paid_subscription': false,
        'branding_enabled': false,
        'welcome_tour_step': null,
        'calendar_event_name': "",
        'phone_number': "",
    },
    'enabled_integrations': {
        'simplypay': false,
        'user_simplypay': false,
        'organization_simplypay': false,
        'sms': false,
        'zoom': false,
        'kopano_meet': false,
        'flexible_duration': false,
        'multiple_meeting': false,
        'event_type_tag': false,
    },
    'path': {
        'host': '',
        'help_url': '',
        'acl_rules': '',
        'payment': '',
        'open_simplypay_event': '',
        'dashboard': {
            'review': {
                'leave': ''
            }
        },
        'search': {
            'relevant_resources': '',
            'stream_gpt': '',
        },
        'file': {
            'image': '',
        },
        'appearance': {
            'user': '',
            'organization': '',
        },
        'widget': {
            'user': '',
            'organization': '',
        },
        'calendar_integration': {
            'calendar_account_list': '',
            'calendar_check_conflicts': '',
            'calendar_event_name_type': '',
            'calendar_default': '',
        },
        'calendar': {
            'event': {
                'list': '',
            },
            'event_type': {
                'list': '',
            }
        },
        'directory': {
            'is_in_directory': '',
            'company_save': '',
            'company_delete': '',
        },
        'event': {
            'invite_participants': '',
            'cancel': '',
            'edit': '',
            'cancel_list': '',
            'delete_list': '',
            'no_show': '',
            'reschedule': '',
            'create': '',
            'notes': '',
            'list': '',
            'event_list_page': '',
            'resend_notification': '',
        },
        'poll': {
            'list': '',
            'create': '',
            'public_poll_result': '',
        },
        'export': {
            'list': '',
            'generate': '',
            'download': '',
        },
        'welcome_tour': {
            'skip_step': '',
            'event_type': '',
        },
        'event_type': {
            'list': '',
            'availability': ''
        },
        'single_use_link': {
            'list': '',
        },
        'organization': {
            'tag': {
                'list': ''
            }
        },
        'user_event_type_tag': {
            'list': ''
        },
        'event_type_tag': {
            'list': ''
        },
        'news': {
            'list': '',
            'view': ''
        },
        'availability_rule': {
            'list': ''
        },
        'user': {
            'list': '',
            'organization_tag': {
                'list': ''
            },
            'skip_tutorial': '',
        },
        'user_relation': {
            'list': '',
            'login': '',
        },
        'custom_translation': {
            'user': {
                'list': '',
            },
            'organization': {
                'list': '',
            }
        },
        'tax': {
            'user': {
                'list': '',
            },
            'organization': {
                'list': '',
            }
        },
        'booking': {
            'availability': ''
        },
        'chart': {
            'list_status': '',
            'hour': '',
            'status': '',
        },
        'api_key': {
            'list': '',
        },
    },
    ...appConfig
};

export default Config;